.raum {
    font-size: 22px;
    font-weight: 500;
}

.datum {
    font-size: 14px;
    font-weight: 300;
}


.temperatur, .feuchte {
    font-size: 36px;
    font-weight: 500;
}

.hc_separator {
    display: block;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: .5px;
    width: 9em;
}

.station {
    margin-right: 10px;
    margin-bottom: 10px;
}

.legende {
    font-family: monospace;
}