body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Home .notes p {
    color: #666;
}
.raum {
    font-size: 22px;
    font-weight: 500;
}

.datum {
    font-size: 14px;
    font-weight: 300;
}


.temperatur, .feuchte {
    font-size: 36px;
    font-weight: 500;
}

.hc_separator {
    display: block;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: .5px;
    width: 9em;
}

.station {
    margin-right: 10px;
    margin-bottom: 10px;
}

.legende {
    font-family: monospace;
}
.special {
    height: 75vh;
    left: 10px; 
    right: 10px;
    top: 10px; 
    bottom: 10px;
}

